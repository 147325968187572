.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.react-datepicker {
  font-size: 18px;
}

.react-datepicker__day {
  width: 32px;
}

.spikes {
  position: relative;
  background: white;
}

.spikes::after {
  content: '';
  position: absolute;
  right: 0;
  left: -0%;
  top: -15px;
  z-index: 10;
  display: block;
  height: 15px;
  background-size: 15px 100%;
  background-image: linear-gradient(135deg, white 25%, transparent 25%), linear-gradient(225deg, white 25%, transparent 25%);
  background-position: 0 0;
  transform: rotate(180deg);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rmdp-container {
  @apply w-full;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

@supports (-webkit-touch-callout: none) {
  .h-screen-ios {
    height: -webkit-fill-available;
  }
}

