@tailwind base;
@tailwind components;
@tailwind utilities;

.dangerouslySetInnerHTML p {
  margin-bottom: 1em;
}

.dangerouslySetInnerHTML a {
  text-decoration: underline;
}
